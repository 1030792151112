import type { Episode, Prisma } from "@prisma/client";
import * as Lucide from "lucide-react";
import { useEffect } from "react";
import Markdown from "react-markdown";
import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from "react-router";
import { Form, Link, useLoaderData, useRevalidator } from "react-router";
import rehypeExternalLinks from "rehype-external-links";
import { db } from "~/.server/db";
import { Content, ContentBody, ContentHeader, ContentTitle } from "~/components/ui/content";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { Loading } from "~/components/ui/loading";
import { formatDateTimeShort } from "~/lib/utils";
import { userFromMatches, useUser } from "~/with-user";
import { getRequestEpisode, type EpisodeWithSummaries, type SummaryWithSource } from "./get.server";
import { episodeShortName } from "./props";
import { publishPendingEpisodes } from "./publish.server";

function ContentFallback({ episode }: { episode: Episode }) {
  if (episode.status === "ok") {
    return <p className="text-sm">Sorry, there was nothing to summarize.</p>;
  }
}

function Preparing() {
  return (
    <div className="flex items-end text-content-foreground/70 uppercase text-xs">
      <span>Preparing</span>
      <Loading className="ml-1 pb-[4px]" />
    </div>
  );
}

function itemMarkdown(item: PrismaJson.SummarySectionItem) {
  let markdown = item.summary;
  if (item.title) {
    if (item.url) {
      markdown = `**[${item.title}](${item.url}):** ${markdown}`;
    } else {
      markdown = `**${item.title}:** ${markdown}`;
    }
  }
  return `  - ${markdown}`;
}

function itemsMarkdown(items: PrismaJson.SummarySectionItem[]) {
  return items.map((item) => itemMarkdown(item)).join("\n");
}

function RenderSourceSummary({ summary }: { summary: SummaryWithSource }) {
  if (summary.summaries.length === 0) return null;

  return (
    <div>
      <h2 className="font-semibold text-xl mt-6 mb-2">{summary.source.sourceName}</h2>
      {summary.summaries.map((summarySection, sectionIndex) => (
        <div key={sectionIndex}>
          <h3 className="font-semibold text-[15px] mt-4 mb-2">
            {summarySection.category ? `${summarySection.category} / ` : ""}
            {summarySection.url ? (
              <a
                href={summarySection.url}
                target="_blank"
                rel="noreferrer"
                className="underline decoration-content-foreground/20 hover:decoration-content-foreground"
              >
                {summarySection.name}
              </a>
            ) : (
              summarySection.name
            )}
            {summarySection.discussionUrl && (
              <a
                href={summarySection.discussionUrl}
                target="_blank"
                rel="noreferrer"
                className="no-underline opacity-50 hover:opacity-100 ml-2"
              >
                💬
              </a>
            )}
          </h3>
          <Markdown className="summary" rehypePlugins={[[rehypeExternalLinks, { target: "_blank" }]]}>
            {itemsMarkdown(summarySection.items)}
          </Markdown>
        </div>
      ))}
    </div>
  );
}

function RenderContent({ episode }: { episode: EpisodeWithSummaries }) {
  return (
    <>
      {episode.summaries.map((summary) => (
        <RenderSourceSummary key={summary.sourceId} summary={summary} />
      ))}
    </>
  );
}

export function EpisodeContent({ episode }: { episode: EpisodeWithSummaries }) {
  const { episodeKey, status, speechStorageUrl } = episode;
  const revalidator = useRevalidator();
  const trackUpdates = status !== "ok";
  const hasContent = episode.summaries.some((summary) => summary.summaries.length > 0);
  useEffect(() => {
    if (trackUpdates) {
      const source = new EventSource(`/${episodeKey}/updates`);
      source.onmessage = (_event) => {
        revalidator.revalidate();
      };
      return () => source.close();
    }
  }, [episodeKey, trackUpdates]);
  if (status === "running" || status === "queued") return <Preparing />;
  const speechUrl = speechStorageUrl ? `/${episodeKey}.mp3` : null;
  return (
    <>
      <EpisodePeriod episode={episode} />
      {speechUrl && (
        <audio className="w-full mb-6" controls>
          <source src={speechUrl} type="audio/mp3" />
          <div style={{ display: "flex" }}>
            <a
              href={speechUrl}
              style={{
                color: "#374151",
                backgroundColor: "#e5e7eb",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                padding: "0.25rem 1rem",
                flexBasis: "content",
                borderRadius: "9999px",
              }}
            >
              <img src="/images/play-dark@2x.png" alt="Play" style={{ width: "1.25em", height: "1.25em" }} />
              <span>Listen to this summary</span>
            </a>
          </div>
        </audio>
      )}
      {hasContent ? <RenderContent episode={episode} /> : <ContentFallback episode={episode} />}
    </>
  );
}

// PAGE DEV ACTIONS

export const action = async (args: ActionFunctionArgs) => {
  const episode = await getRequestEpisode(args);
  const formData = await args.request.formData();
  const action = formData.get("action");

  const episodeData: Prisma.EpisodeUpdateInput = {
    scheduledAt: new Date(),
    status: "queued",
  };

  if (action === "regenerate" || action === "regenerate-summary" || action === "regenerate-speech") {
    episodeData.speechStorageUrl = null;
  }

  await db.episode.update({
    where: {
      userId_episodeKey: {
        userId: episode.userId,
        episodeKey: episode.episodeKey,
      },
    },
    data: episodeData,
  });

  if (action === "regenerate") {
    await db.sourceSummary.deleteMany({
      where: {
        userId: episode.userId,
        episodeKey: episode.episodeKey,
      },
    });
  }

  if (action === "regenerate-summary") {
    await db.sourceSummary.updateMany({
      where: {
        userId: episode.userId,
        episodeKey: episode.episodeKey,
        status: "summary",
      },
      data: {
        summaries: [],
        status: "content",
      },
    });
  }

  await publishPendingEpisodes();

  return null;
};

function DevButtons({ episode }: { episode: Episode }) {
  const disabled = false; // episode.status !== "ok" && episode.status !== "error";
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Lucide.Wrench className="size-4 text-gray-600 group-hover:text-gray-900 ml-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent asChild align="end">
        <Form method="post" className="flex flex-col">
          <DropdownMenuItem asChild>
            <Link to={`/${episode.episodeKey}/-raw-summaries`} target="_blank">
              <Lucide.Inspect />
              <span>Show raw summaries</span>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate" type="submit" disabled={disabled}>
              <Lucide.RefreshCcw />
              <span>Regenerate all</span>
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate-summary" type="submit" disabled={disabled}>
              <Lucide.Merge />
              <span>Regenerate summary</span>
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate-speech" type="submit" disabled={disabled}>
              <Lucide.Speech />
              <span>Regenerate speech</span>
            </button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <button name="action" value="regenerate-email" type="submit" disabled={disabled}>
              <Lucide.Mail />
              <span>Regenerate email</span>
            </button>
          </DropdownMenuItem>
        </Form>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

// PAGE LOADER

export async function loader(args: LoaderFunctionArgs) {
  const episode = await getRequestEpisode(args);
  const [nextEpisode, previousEpisode] = await Promise.all([
    db.episode.findFirst({
      where: {
        userId: episode.userId,
        untilTime: { gt: episode.untilTime },
        status: { not: "queued" },
      },
      orderBy: { untilTime: "asc" },
      select: { episodeKey: true },
    }),
    db.episode.findFirst({
      where: {
        userId: episode.userId,
        untilTime: { lt: episode.untilTime },
        status: { not: "queued" },
      },
      orderBy: { untilTime: "desc" },
      select: { episodeKey: true },
    }),
  ]);
  return { episode, nextEpisodeKey: nextEpisode?.episodeKey, previousEpisodeKey: previousEpisode?.episodeKey };
}

// PREV / NEXT

function NavLink({
  IconLeft,
  IconRight,
  label,
  episodeKey,
}: {
  IconLeft?: React.ComponentType<Lucide.LucideProps>;
  IconRight?: React.ComponentType<Lucide.LucideProps>;
  label: string;
  episodeKey?: string;
}) {
  if (episodeKey) {
    return (
      <Link
        to={`/${episodeKey}`}
        className="text-sm font-medium flex items-center text-gray-500 hover:text-gray-600 hover:bg-gray-100 rounded-full select-none"
      >
        {IconLeft && <IconLeft className="size-4 -mr-2" />}
        <span className="mx-3">{label}</span>
        {IconRight && <IconRight className="size-4 -ml-2" />}
      </Link>
    );
  }
  return (
    <span className="text-sm font-medium flex items-center text-gray-300 select-none">
      {IconLeft && <IconLeft className="size-4 -mr-2" />}
      <span className="mx-3">{label}</span>
      {IconRight && <IconRight className="size-4 -ml-2" />}
    </span>
  );
}

// PERIOD

export function EpisodePeriod({ episode }: { episode: Episode }) {
  const user = useUser();
  return episode.fromTime && episode.untilTime ? (
    <div className="text-xs text-content-foreground/70 mb-6 font-normal uppercase">
      <span>Summary for </span>
      <span className="font-semibold">{formatDateTimeShort(episode.fromTime, user)}</span>
      <span> → </span>
      <span className="font-semibold">{formatDateTimeShort(episode.untilTime, user)}</span>
    </div>
  ) : null;
}

// PAGE

export default function EpisodePage() {
  const { episode, nextEpisodeKey, previousEpisodeKey } = useLoaderData<typeof loader>();
  const user = useUser();

  return (
    <Content className="content-w mb-6">
      <ContentHeader className="relative px-10 py-8 pb-0">
        <ContentTitle className="flex items-center">
          <span className="flex-grow">{episodeShortName(episode, user)}</span>
          <span className="text-base font-normal text-gray-500 flex items-center">
            <NavLink IconLeft={Lucide.ChevronLeft} label="Next" episodeKey={nextEpisodeKey} />
            <NavLink IconRight={Lucide.ChevronRight} label="Previous" episodeKey={previousEpisodeKey} />
            {user.devMode && <DevButtons episode={episode} />}
          </span>
        </ContentTitle>
      </ContentHeader>
      <ContentBody className="px-10 py-8">
        <EpisodeContent episode={episode} />
      </ContentBody>
    </Content>
  );
}

// PAGE META

export const meta: MetaFunction = ({ data, matches }) => {
  const { episode } = data as ReturnType<typeof useLoaderData<typeof loader>>;
  const user = userFromMatches(matches);
  return [{ title: `${episodeShortName(episode, user)} · Sumcast` }];
};
