import * as Lucide from "lucide-react";
import { Link } from "react-router";
import { Button } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { SignOutItem } from "../user/signout";
import { sidebarItems } from "./items";

export function SettingsMenu() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="rounded-full mr-2 size-7 text-foreground/50 p-0 hover:bg-card group hover:outline hover:outline-1 hover:outline-border hover:shadow"
        >
          <div className="size-4 outline outline-foreground/10 rounded-full p-0.25 group-hover:outline-none">
            <Lucide.ChevronDown />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuGroup>
          <DropdownMenuLabel>Settings</DropdownMenuLabel>
          {sidebarItems.map((item) => (
            <DropdownMenuItem asChild key={item.href}>
              <Link to={item.href}>
                <item.Icon className="size-4" />
                {item.name}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <SignOutItem />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
