import { Label } from "~/components/ui/label";
import { Slider } from "~/components/ui/slider";
import { SettingsDescription, SettingsRow } from "~/settings/layout";
import { charsToLength, lengthName, lengthToChars, SourceTypeSettings, type SummaryLength } from "~/settings/source";
import { DEFAULT_MAX_TOPIC_CHARS, DEFAULT_MAX_TOPICS, DEFAULT_POSTS, type HNSourceConfig } from "./config";

SourceTypeSettings.method("hn", ({ config, setConfig }) => {
  const hnConfig = config as HNSourceConfig;

  const posts = hnConfig.posts ?? DEFAULT_POSTS;
  const maxTopicsPerPost = hnConfig.maxTopicsPerPost ?? DEFAULT_MAX_TOPICS;
  const maxTopicChars = hnConfig.maxTopicChars ?? DEFAULT_MAX_TOPIC_CHARS;
  const summaryLength = charsToLength(maxTopicChars);

  return (
    <>
      <SettingsRow>
        <Label>Top posts</Label>
        <div className="flex-grow" />
        <div className="text-sm">{posts}</div>
        <div className="w-32 flex-shrink-0">
          <Slider
            defaultValue={[posts]}
            min={1}
            max={30}
            step={1}
            onValueChange={([value]) => setConfig({ ...hnConfig, posts: value })}
          />
        </div>
      </SettingsRow>
      <SettingsDescription>The number of posts to summarize.</SettingsDescription>

      <SettingsRow>
        <Label>Discussion themes</Label>
        <div className="flex-grow" />
        <div className="text-sm">{maxTopicsPerPost}</div>
        <div className="w-32 flex-shrink-0">
          <Slider
            defaultValue={[maxTopicsPerPost]}
            min={1}
            max={10}
            step={1}
            onValueChange={([value]) => setConfig({ ...hnConfig, maxTopicsPerPost: value })}
          />
        </div>
      </SettingsRow>
      <SettingsDescription>The number of discussion themes to summarize per story.</SettingsDescription>

      <SettingsRow>
        <Label>Summary length</Label>
        <div className="flex-grow" />
        <div className="text-sm">{lengthName(summaryLength)}</div>
        <div className="w-32 flex-shrink-0">
          <Slider
            defaultValue={[summaryLength]}
            min={1}
            max={3}
            step={1}
            onValueChange={([value]) =>
              setConfig({ ...hnConfig, maxTopicChars: lengthToChars(value as SummaryLength) })
            }
          />
        </div>
      </SettingsRow>
    </>
  );
});
