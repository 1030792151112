import * as Lucide from "lucide-react";
import { Link, type LoaderFunctionArgs, Outlet, useLocation, useRouteLoaderData } from "react-router";
import { TooltipProvider } from "~/components/ui/tooltip";
import { cn, invariant, uuidToB58 } from "~/lib/utils";
import { getUserSources } from "~/source/get.server";
import { getSourceMeta } from "~/source/meta";
import { requireAppToken } from "~/user/token.server";
import { sidebarItems } from "./items";

export async function loader({ request }: LoaderFunctionArgs) {
  const token = await requireAppToken(request);
  const sources = await getUserSources(token.userId);
  return { sources };
}

export function useSources() {
  const sources = useRouteLoaderData<typeof loader>("settings/layout")?.sources;
  invariant(sources, "No sources");
  return sources;
}

export type SettingsSource = Awaited<ReturnType<typeof loader>>["sources"][number];

const itemClass =
  "px-2 py-1 flex flex-row gap-2 rounded-full items-center hover:bg-card hover:text-card-foreground hover:shadow hover:outline hover:outline-1 hover:outline-border";

const activeItemClass = cn(itemClass, "bg-card text-card-foreground shadow outline outline-1 outline-border");

function SidebarSource({ source }: { source: SettingsSource }) {
  const location = useLocation();
  const href = `/settings/sources/${uuidToB58(source.sourceId)}`;
  const meta = getSourceMeta(source.sourceType);
  const isActive = location.pathname === href;
  return (
    <li>
      <Link to={href} className={cn(itemClass, isActive && activeItemClass)}>
        <img src={meta.icon} alt={meta.name} className={cn("size-4", !source.enabled && "grayscale")} />
        <span className="truncate">{source.sourceName}</span>
      </Link>
    </li>
  );
}

function SidebarSources() {
  const sources = useSources();
  return (
    <ul className="mt-2 ml-6 flex flex-col gap-2">
      {sources.map((source) => (
        <SidebarSource key={source.sourceId} source={source} />
      ))}
    </ul>
  );
}

function SidebarItem({ name, Icon, href }: (typeof sidebarItems)[number]) {
  const location = useLocation();
  const isActive = location.pathname === href;
  const showSources = href === "/settings/sources";
  return (
    <li key={href}>
      <Link to={href} className={cn(itemClass, isActive && activeItemClass)}>
        <Icon className="size-4" />
        <span>{name}</span>
      </Link>
      {showSources && <SidebarSources />}
    </li>
  );
}

function Sidebar() {
  return (
    <ul className="flex flex-col gap-2 text-sm pr-4 text-muted-foreground w-48 flex-shrink-0">
      <li key="exit" className="my-2">
        <a href="/" className={cn(itemClass, "text-base")}>
          <Lucide.ChevronLeft className="size-4" />
          <span>Back to app</span>
        </a>
      </li>
      {sidebarItems.map((item) => (
        <SidebarItem key={item.href} {...item} />
      ))}
    </ul>
  );
}

export function SettingsRow({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div
      className={cn(
        "flex flex-row items-center justify-between border-t border-content-foreground/10 mt-6 pt-6 gap-2 -mx-6 px-6",
        className,
      )}
    >
      {children}
    </div>
  );
}

export function SettingsDescription({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={cn("text-sm text-content-foreground/50 mt-4 flex flex-row items-start", className)}>{children}</div>
  );
}

export default function SettingsLayout() {
  return (
    <TooltipProvider>
      <div className="flex flex-row items-stretch flex-grow max-w-3xl mx-auto py-16">
        <Sidebar />
        <main className="flex-grow flex-shrink">
          <Outlet />
        </main>
      </div>
    </TooltipProvider>
  );
}
