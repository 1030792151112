import type { SourceType } from "@prisma/client";

import { multimethod } from "@whimsical-code/multimethod";

export interface SourceMeta {
  name: string;
  description: string;
  icon: string;
  authPath?: string;
  authDialog?: boolean; // if true, needs to implement source/auth.tsx@AuthDialogContent
  singleton?: boolean;
}

export const getSourceMeta = multimethod<[SourceType], SourceType, SourceMeta>((sourceType) => sourceType);

export const allSourceTypes: SourceType[] = ["whimsical", "slack", "reddit", "hn", "bsky"];
