import { Link } from "react-router";
import { Logo } from "~/components/logo";

/**
 * Layout for supporting pages.
 */
export function UtilLayout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-col h-full max-h-[30em] items-center justify-center p-4 max-w-[38em] mx-auto">
      <Link to="/" className="flex flex-row items-center px-1 rounded-md text-gray-900 hover:bg-white mb-8">
        <Logo className="text-2xl" />
      </Link>
      {children}
    </div>
  );
}
