import type { Source } from "@prisma/client";
import { multimethod } from "@whimsical-code/multimethod";
import { useCallback, useState } from "react";
import {
  Form,
  redirect,
  useLoaderData,
  useSubmit,
  type ActionFunctionArgs,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "react-router";
import { db } from "~/.server/db";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/components/ui/alert-dialog";
import { Button } from "~/components/ui/button";
import { Content, ContentBody, ContentHeader, ContentTitle } from "~/components/ui/content";
import { Label } from "~/components/ui/label";
import { Switch } from "~/components/ui/switch";
import { b58ToUuid, cn, invariant } from "~/lib/utils";
import { getSourceMeta } from "~/source/meta";
import { requireAppToken } from "~/user/token.server";
import type { SettingsSource } from "./layout";
import { SettingsRow } from "./layout";

function getSourceId(params: LoaderFunctionArgs["params"]) {
  invariant(params.sourceId, "sourceId is required");
  const sourceId = b58ToUuid(params.sourceId);
  if (!sourceId) {
    console.error(`Invalid sourceId: ${params.sourceId}`);
    throw new Response("Not Found", { status: 404 });
  }
  return sourceId;
}

export async function loader({ request, params }: LoaderFunctionArgs) {
  const token = await requireAppToken(request);
  const sourceId = getSourceId(params);
  const source = await db.source.findUnique({ where: { sourceId, userId: token.userId } });
  if (!source) {
    console.error(`Source not found: ${params.sourceId}`);
    throw new Response("Not Found", { status: 404 });
  }
  return { source };
}

export async function action(args: ActionFunctionArgs) {
  const { request, params } = args;
  const { userId } = await requireAppToken(request);
  const formData = await request.formData();
  const action = formData.get("action");
  if (action === "set-enabled") {
    const sourceId = getSourceId(params);
    const enabled = formData.get("enabled") === "true";
    await db.source.update({ where: { sourceId, userId }, data: { enabled } });
  } else if (action === "set-config") {
    const sourceId = getSourceId(params);
    const config = JSON.parse(formData.get("config") as string);
    if (!config) {
      throw new Error("Invalid config");
    }
    await db.source.update({ where: { sourceId, userId }, data: { config } });
  } else if (action === "remove-source") {
    return await removeSource(args);
  }
}

function EnabledToggle({ source }: { source: Source }) {
  const submit = useSubmit();
  const [enabled, _setEnabled] = useState(source.enabled);
  function setEnabled(enabled: boolean) {
    _setEnabled(enabled);
    const formData = new FormData();
    formData.append("action", "set-enabled");
    formData.append("enabled", enabled.toString());
    submit(formData, { method: "post" });
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <Label htmlFor="enabled" className="text-sm text-muted-foreground">
        Enabled
      </Label>
      <Switch name="enabled" checked={enabled} onCheckedChange={setEnabled} />
    </div>
  );
}

export const SourceTypeSettings = multimethod(
  ({
    source,
  }: {
    source: SettingsSource;
    config: SettingsSource["config"];
    setConfig: (config: SettingsSource["config"]) => void;
  }) => source.sourceType,
  (_): React.ReactNode => null,
);

function SourceConfig({ source }: { source: SettingsSource }) {
  const [config, _setConfig] = useState(source.config);
  const submit = useSubmit();
  const setConfig = useCallback(
    (config: SettingsSource["config"]) => {
      _setConfig(config);
      const formData = new FormData();
      formData.append("action", "set-config");
      formData.append("config", JSON.stringify(config));
      submit(formData, { method: "post" });
    },
    [submit],
  );

  return <SourceTypeSettings source={source} config={config} setConfig={setConfig} />;
}

// REMOVE SOURCE

async function removeSource(args: ActionFunctionArgs) {
  const token = await requireAppToken(args.request);
  const sourceId = getSourceId(args.params);
  await db.source.delete({ where: { sourceId, userId: token.userId } });
  return redirect("/settings/sources");
}

function RemoveSource({ source }: { source: SettingsSource }) {
  return (
    <SettingsRow>
      <Label>Remove source</Label>

      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button
            type="button"
            variant="outline"
            className="text-destructive dark:text-destructive-foreground hover:bg-destructive hover:text-destructive-foreground"
          >
            Remove...
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Please confirm removal of {source.sourceName}</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Form method="post">
              <Button type="submit" name="action" value="remove-source" variant="destructive">
                Remove Source
              </Button>
            </Form>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </SettingsRow>
  );
}

export default function SourcePage() {
  const { source } = useLoaderData<typeof loader>();
  const meta = getSourceMeta(source.sourceType);

  return (
    // need key to force re-mount when source changes
    <Content key={source.sourceId}>
      <ContentHeader className="pb-0">
        <ContentTitle className="flex items-center">
          <img src={meta.icon} className={cn("size-6 mr-4", !source.enabled && "grayscale")} alt={meta.name} />
          <span className="flex-grow">{source.sourceName}</span>
          <EnabledToggle source={source} />
        </ContentTitle>
      </ContentHeader>
      <ContentBody>
        <SourceConfig source={source} />
        <RemoveSource source={source} />
      </ContentBody>
    </Content>
  );
}

// PAGE META

export const meta: MetaFunction = ({ data }) => {
  const { source } = data as ReturnType<typeof useLoaderData<typeof loader>>;
  return [{ title: `${source.sourceName} · Sumcast` }];
};

// SUMMARY LENGTH

export type SummaryLength = 1 | 2 | 3;

export function charsToLength(chars: number | undefined): SummaryLength {
  if (!chars) return 2;
  if (chars < 300) return 1;
  if (chars > 300) return 3;
  return 2;
}

export function lengthToChars(length: SummaryLength) {
  if (length === 1) return 100;
  if (length === 3) return 900;
  return 300;
}

export function lengthName(length: SummaryLength) {
  if (length === 1) return "Short";
  if (length === 3) return "Long";
  return "Medium";
}
