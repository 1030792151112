import type { ComAtprotoServerCreateSession } from "@atproto/api";
import type { Prisma } from "@prisma/client";
import { useEffect } from "react";
import { useFetcher, type ActionFunction } from "react-router";
import { Button } from "~/components/ui/button";
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from "~/components/ui/dialog";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { addSource } from "~/source/add.server";
import { AuthDialogContent } from "~/source/auth";
import { requireAppToken } from "~/user/token.server";
import { createBskyAgent } from "./agent.server";

export interface BskyAuth extends Prisma.JsonObject {
  identifier: string;
  password: string;
}

export const action: ActionFunction = async ({ request }) => {
  const token = await requireAppToken(request);

  const formData = await request.formData();
  const identifier = formData.get("identifier") as string;
  const password = formData.get("password") as string;

  const agent = createBskyAgent();

  let res: ComAtprotoServerCreateSession.Response;
  try {
    res = await agent.login({ identifier, password });
  } catch (error) {
    console.warn(`Error authenticating Bluesky account for user ${token.userId}: ${error}`);
    return {
      error: "invalid",
    };
  }

  if (res.data.active === false) {
    return {
      error: "inactive",
    };
  }

  // even refreshJwt appears to expire after few hours
  // const session: AtpSessionData = {
  //   accessJwt: res.data.accessJwt,
  //   refreshJwt: res.data.refreshJwt,
  //   did: res.data.did,
  //   handle: res.data.handle,
  //   active: res.data.active ?? true,
  // };

  const auth: BskyAuth = {
    identifier,
    password,
  };

  await addSource({
    userId: token.userId,
    externalId: res.data.did,
    sourceType: "bsky",
    sourceName: "Bluesky",
    auth,
  });

  return {
    success: true,
  };
};

AuthDialogContent.method("bsky", ({ closeDialog }) => {
  const fetcher = useFetcher();

  useEffect(() => {
    if (fetcher.data?.success) {
      closeDialog();
    }
  }, [fetcher.data, closeDialog]);

  return (
    <DialogContent className="sm:max-w-[524px]">
      <fetcher.Form method="post" action="/bsky/auth">
        <DialogHeader>
          <DialogTitle>Connect Bluesky account</DialogTitle>
          <DialogDescription>
            To connect your Bluesky account, please{" "}
            <a
              href="https://bsky.app/settings/app-passwords"
              target="_blank"
              rel="noreferrer"
              className="text-gray-900 underline decoration-gray-400 hover:decoration-gray-900"
            >
              create a new app password
            </a>
            .
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col">
          <div className="flex items-center justify-between mt-4">
            <div>
              <Label htmlFor="identifier">Username or email</Label>
            </div>
            {fetcher.data?.error && fetcher.state === "idle" && (
              <div className="text-red-500 text-sm">
                {fetcher.data.error === "inactive" ? "This account is not active." : "Incorrect username or password."}
              </div>
            )}
          </div>
          <Input id="identifier" name="identifier" className="mt-2" />

          <div className="mt-4">
            <Label htmlFor="password">App password</Label>
          </div>
          <Input id="password" name="password" type="password" className="mt-2" />

          <Button type="submit" className="mt-4">
            Add Bluesky
          </Button>
        </div>
      </fetcher.Form>
    </DialogContent>
  );
});
