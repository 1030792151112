import { Label } from "~/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { Slider } from "~/components/ui/slider";
import { Switch } from "~/components/ui/switch";
import { SettingsDescription, SettingsRow } from "~/settings/layout";
import { charsToLength, lengthName, lengthToChars, SourceTypeSettings, type SummaryLength } from "~/settings/source";
import { DEFAULT_MAX_POST_CHARS, type WhimsicalSourceConfig } from "./config";

SourceTypeSettings.method("whimsical", ({ config, setConfig }) => {
  const whimsicalConfig = config as WhimsicalSourceConfig;

  const teamFilter = whimsicalConfig.onlyJoinedTeams ? "joined" : "all";
  const setTeamFilter = (teamFilter: "joined" | "all") => {
    setConfig({ ...whimsicalConfig, onlyJoinedTeams: teamFilter === "joined" });
  };

  const maxPostChars = whimsicalConfig.maxPostChars ?? DEFAULT_MAX_POST_CHARS;
  const summaryLength = charsToLength(maxPostChars);

  return (
    <>
      <SettingsRow>
        <Label>Teams</Label>
        <Select value={teamFilter} onValueChange={setTeamFilter}>
          <SelectTrigger className="w-32">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="joined">Only joined</SelectItem>
            <SelectItem value="all">All</SelectItem>
          </SelectContent>
        </Select>
      </SettingsRow>
      <SettingsDescription>Choose which teams to include in summary.</SettingsDescription>

      <SettingsRow>
        <Label>Include project posts</Label>
        <Switch
          checked={whimsicalConfig.includeProjects ?? false}
          onCheckedChange={(checked) => setConfig({ ...whimsicalConfig, includeProjects: checked })}
        />
      </SettingsRow>

      <SettingsRow>
        <Label>Summary length</Label>
        <div className="flex-grow" />
        <div className="text-sm">{lengthName(summaryLength)}</div>
        <div className="w-32 flex-shrink-0">
          <Slider
            defaultValue={[summaryLength]}
            min={1}
            max={3}
            step={1}
            onValueChange={([value]) =>
              setConfig({ ...whimsicalConfig, maxPostChars: lengthToChars(value as SummaryLength) })
            }
          />
        </div>
      </SettingsRow>
    </>
  );
});
