import type { Prisma } from "@prisma/client";

export const DEFAULT_MAX_TOPICS_PER_CHANNEL = 3;
export const DEFAULT_MAX_TOPIC_CHARS = 300;

export interface SlackSourceConfig extends Prisma.JsonObject {
  onlyJoinedChannels: boolean;
  maxTopicsPerChannel?: number;
  maxTopicChars?: number;
}
