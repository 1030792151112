import { Link, type MetaFunction, Outlet } from "react-router";
import { Logo } from "./components/logo";
import { SettingsMenu } from "./settings/menu";

// HEADER

function Header() {
  return (
    <div className="w-full">
      <div className="flex flex-row items-center content-w pt-8 pb-4">
        <Link
          to="/"
          className="flex flex-row items-center rounded-full text-foreground hover:bg-card hover:outline-border hover:shadow"
        >
          <Logo />
        </Link>
        <SettingsMenu />
        <div className="flex-grow" />
      </div>
    </div>
  );
}

export default function MainLayout() {
  return (
    <div className="flex flex-col h-full">
      <Header />
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
}

export const meta: MetaFunction = () => {
  return [{ title: "Sumcast" }, { name: "description", content: "Summarize Slack conversations as a daily podcast." }];
};
