import type { User } from "@prisma/client";
import type { Params } from "react-router";
import { formatDate } from "~/lib/utils";

export function createEpisodeKey(untilTime: Date) {
  return untilTime.toISOString().slice(0, 10);
}

export function episodeShortName(
  episode: { episodeKey: string; untilTime: Date },
  user: { locale: string; timeZone: string },
) {
  return formatDate(episode.untilTime, user, {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
}

export function episodeLongName(
  episode: { episodeKey: string; untilTime: Date },
  user: { locale: string; timeZone: string },
) {
  const dayOfWeek = formatDate(episode.untilTime, user, { weekday: "long" });
  const date = formatDate(episode.untilTime, user, {
    month: "long",
    day: "numeric",
  });
  return `${dayOfWeek} Sumcast, ${date}`;
}

export function requireEpisodeKey(params: Params) {
  if (!params.episodeKey) {
    throw new Response("Not Found", { status: 404 });
  }
  return params.episodeKey;
}

export interface EpisodeId {
  userId: string;
  episodeKey: string;
}

export function episodeChannel(episodeId: EpisodeId) {
  return `${episodeId.userId}/${episodeId.episodeKey}`;
}

export function nextEpisodePublishDay({ untilTime }: { untilTime: Date }, user: User, noOnPrefix = false) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  if (untilTime.toDateString() === today.toDateString()) {
    return "today";
  }
  if (untilTime.toDateString() === tomorrow.toDateString()) {
    return "tomorrow";
  }
  return `${noOnPrefix ? "" : "on "}${formatDate(untilTime, user, {
    weekday: "long",
    month: "long",
    day: "numeric",
  })}`;
}
