import { cn } from "~/lib/utils";

export function Logo({ className }: { className?: string }) {
  return (
    <span className={cn("flex items-center", className)}>
      <img src="/favicon.svg" alt="logo" className="size-5 m-1 mr-2" />
      <span className="font-semibold">Sumcast</span>
      <span className="inline-block mx-1.5 mb-1.5 text-[0.7rem] leading-[1rem] tracking-wide font-bold italic spacing text-foreground/60  rounded-md">
        βeta
      </span>
    </span>
  );
}
