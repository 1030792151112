/**
 * Public home page
 */
import { useEffect, useRef, useState } from "react";
import { type ActionFunctionArgs, Form, type MetaFunction, useActionData, useNavigation } from "react-router";
import { Logo } from "./components/logo";
import { Button } from "./components/ui/button";
import { Content, ContentBody } from "./components/ui/content";
import { Input } from "./components/ui/input";
import { signInWithEmailCode, startEmailSignIn } from "./user/signin.server";

export const meta: MetaFunction = () => {
  return [
    { title: "Sumcast" },
    { name: "description", content: "Get a summary of what is happening in Slack as a daily podcast or email." },
  ];
};

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const action = formData.get("action") as string;
  if (action === "start-signin") {
    const email = formData.get("email") as string;
    const timeZone = (formData.get("timeZone") as string | null) ?? undefined;
    const locale = (formData.get("locale") as string | null) ?? undefined;
    await startEmailSignIn({ email, timeZone, locale });
    return {
      email,
    };
  }
  if (action === "verify-email") {
    const email = formData.get("email") as string;
    const code = formData.get("code") as string;
    try {
      return await signInWithEmailCode({ email, code });
    } catch (e) {
      return {
        email,
        error: true,
      };
    }
  }
}

export function VerifyEmail({ email }: { email: string }) {
  const navigation = useNavigation();
  const data = useActionData<typeof action>();
  const error = data && "error" in data ? data.error : false;
  const [code, setCode] = useState("");
  const codeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (navigation.state === "idle") {
      codeRef.current?.focus();
      codeRef.current?.select();
    }
  }, [navigation.state]);

  const canSubmit = /^\d{6}$/.test(code) && navigation.state !== "submitting";

  return (
    <Content>
      <ContentBody className="p-6">
        <Form method="post" className="flex flex-col gap-2">
          <div className="text-sm text-muted-foreground  mb-2">Please check your email for a verification code:</div>
          {error && <div className="text-destructive text-sm">Sorry, the code is expired or invalid.</div>}
          <Input
            name="code"
            type="text"
            placeholder="Verification code"
            ref={codeRef}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Input name="email" type="hidden" value={email} />
          <Button type="submit" name="action" value="verify-email" className="mt-2" disabled={!canSubmit}>
            Continue
          </Button>
        </Form>
      </ContentBody>
    </Content>
  );
}

export function SignIn() {
  const navigation = useNavigation();

  const [opt, setOpt] = useState(null as { timeZone: string; locale: string } | null);
  useEffect(() => {
    const options = Intl.DateTimeFormat().resolvedOptions();
    setOpt({ timeZone: options.timeZone, locale: options.locale });
  }, []);

  const canSubmit = navigation.state !== "submitting";

  return (
    <Form method="post" className="flex flex-row gap-2">
      <Input name="email" type="email" placeholder="Your email" className="w-72 bg-card" />
      {opt?.timeZone && <Input name="timeZone" type="hidden" value={opt.timeZone} />}
      {opt?.locale && <Input name="locale" type="hidden" value={opt.locale} />}
      <Button type="submit" name="action" value="start-signin" disabled={!canSubmit}>
        Get started
      </Button>
    </Form>
  );
}

export default function Home() {
  const data = useActionData<typeof action>();
  const email = data && "email" in data ? data.email : null;
  return (
    <div className="flex flex-col h-full max-h-[30em] items-center justify-center p-4 max-w-[38em] mx-auto">
      <Logo className="text-2xl mb-8" />
      {email ? (
        <VerifyEmail email={email} />
      ) : (
        <>
          <p className="mb-8 text-center text-muted-foreground">
            Get a summary of Slack conversations as a daily newsletter or podcast.
          </p>
          <SignIn />
        </>
      )}
    </div>
  );
}
