import { Label } from "~/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "~/components/ui/select";
import { Slider } from "~/components/ui/slider";
import { SettingsDescription, SettingsRow } from "~/settings/layout";
import { charsToLength, lengthName, lengthToChars, SourceTypeSettings, type SummaryLength } from "~/settings/source";
import { DEFAULT_MAX_TOPIC_CHARS, DEFAULT_MAX_TOPICS_PER_CHANNEL, type SlackSourceConfig } from "./config";

SourceTypeSettings.method("slack", ({ config, setConfig }) => {
  const slackConfig = config as SlackSourceConfig;

  const channelFilter = slackConfig.onlyJoinedChannels ? "joined" : "all";
  const setChannelFilter = (channelFilter: "joined" | "all") => {
    setConfig({ ...slackConfig, onlyJoinedChannels: channelFilter === "joined" });
  };

  const maxTopicsPerChannel = slackConfig.maxTopicsPerChannel ?? DEFAULT_MAX_TOPICS_PER_CHANNEL;
  const maxTopicChars = slackConfig.maxTopicChars ?? DEFAULT_MAX_TOPIC_CHARS;
  const summaryLength = charsToLength(maxTopicChars);

  return (
    <>
      <SettingsRow>
        <Label>Channels</Label>
        <Select value={channelFilter} onValueChange={setChannelFilter}>
          <SelectTrigger className="w-32">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="joined">Only joined</SelectItem>
            <SelectItem value="all">All</SelectItem>
          </SelectContent>
        </Select>
      </SettingsRow>
      <SettingsDescription>Choose which channels to include in summary.</SettingsDescription>

      <SettingsRow>
        <Label>Themes</Label>
        <div className="flex-grow" />
        <div className="text-sm">{maxTopicsPerChannel}</div>
        <div className="w-32 flex-shrink-0">
          <Slider
            defaultValue={[maxTopicsPerChannel]}
            min={1}
            max={10}
            step={1}
            onValueChange={([value]) => setConfig({ ...slackConfig, maxTopicsPerChannel: value })}
          />
        </div>
      </SettingsRow>
      <SettingsDescription>
        The maximum number of themes to summarize per channel. Themes with the most messages and reactions will have the
        highest priority.
      </SettingsDescription>

      <SettingsRow>
        <Label>Summary length</Label>
        <div className="flex-grow" />
        <div className="text-sm">{lengthName(summaryLength)}</div>
        <div className="w-32 flex-shrink-0">
          <Slider
            defaultValue={[summaryLength]}
            min={1}
            max={3}
            step={1}
            onValueChange={([value]) =>
              setConfig({ ...slackConfig, maxTopicChars: lengthToChars(value as SummaryLength) })
            }
          />
        </div>
      </SettingsRow>
    </>
  );
});
