import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export function nonBlank(value: string | undefined): string | undefined {
  if (value && value.trim().length > 0) {
    return value;
  }
  return undefined;
}

export function formatDate(
  date: Date,
  user?: { locale: string; timeZone: string },
  options?: Intl.DateTimeFormatOptions,
) {
  return date.toLocaleString(user?.locale, {
    timeZone: user?.timeZone,
    ...options,
  });
}

export function formatDateTimeShort(time: Date, user: { locale: string; timeZone: string }) {
  return formatDate(time, user, {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export function dateSlug(date: Date) {
  return date.toISOString().slice(0, 19).replace(/[-T:]/g, "");
}

export function slugDate(slug: string) {
  return new Date(slug.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1-$2-$3 $4:$5:$6"));
}

export function keep<T, R>(xs: readonly T[], fn: (x: T, index: number) => R | undefined): NonNullable<R>[] {
  let i = 0;
  return xs.reduce((result, x) => {
    const y = fn(x, i);
    if (y != null) {
      result.push(y);
    }
    i++;
    return result;
  }, [] as NonNullable<R>[]);
}

const base58 = "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";

export function uuidToB58(uuid: string) {
  const uuidBytes =
    uuid
      .replace(/-/g, "")
      .match(/.{2}/g)
      ?.map((byte) => Number.parseInt(byte, 16)) ?? [];
  let num = 0n;
  for (const byte of uuidBytes) {
    num = num * 256n + BigInt(byte);
  }
  let encoded = "";
  while (num > 0n) {
    encoded = base58[Number(num % 58n)] + encoded;
    num = num / 58n;
  }
  // Add leading 1s for leading zeros in UUID
  const padding = uuidBytes.findIndex((byte) => byte !== 0);
  return "1".repeat(padding) + encoded;
}

export function b58ToUuid(b58id: string) {
  // Count leading 1s to determine padding zeros
  const padding = b58id.match(/^1*/)?.[0].length ?? 0;
  let num = 0n;

  // Convert base58 string to number
  for (const char of b58id.slice(padding)) {
    const value = base58.indexOf(char);
    if (value === -1) {
      console.error("Invalid b58id", b58id);
      return null;
    }
    num = num * 58n + BigInt(value);
  }

  // Convert to hex string with proper padding
  let hex = num.toString(16).padStart(32, "0");

  // Add leading zeros based on padding count
  hex = "00".repeat(padding) + hex;

  // Format as UUID
  return `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${hex.slice(12, 16)}-${hex.slice(16, 20)}-${hex.slice(20)}`;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function escapeXml(s: string): string {
  return s.replace(/[<>&'"]/g, (c: string): string => {
    switch (c) {
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case "&":
        return "&amp;";
      case "'":
        return "&apos;";
      case '"':
        return "&quot;";
    }
    return c;
  });
}

export function formatTimeZone(timeZone: string) {
  try {
    const comps = timeZone.split("/");
    const city = comps[comps.length - 1];
    return city.replace(/_/g, " ");
  } catch {
    return timeZone;
  }
}

export function formatTimeZoneOffset(timeZone: string) {
  return new Date().toLocaleString("en-US", { timeZone, timeZoneName: "longOffset" }).split(" ").pop();
}

export function getTimeZoneOffset(timeZone: string) {
  const offset = new Date().toLocaleString("en-US", { timeZone, timeZoneName: "longOffset" }).split(" ").pop();
  const matchData = offset?.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) return 0;
  const [, sign, hour, minute] = matchData;
  let result = Number.parseInt(hour) * 60;
  if (sign === "-") result *= -1;
  if (minute) result += Number.parseInt(minute);
  return result;
}

/**
 * Use invariant() to assert state which your program assumes to be true.
 *
 * Provide sprintf-style format (only %s is supported) and arguments
 * to provide information about what broke and what you were
 * expecting.
 *
 * The invariant message will be stripped in production, but the invariant
 * will remain to ensure logic does not differ in production.
 */

const NODE_ENV = process.env.NODE_ENV;

export function invariant(
  testValue: unknown,
  format: string,
  a?: unknown,
  b?: unknown,
  c?: unknown,
  d?: unknown,
  e?: unknown,
  f?: unknown,
): asserts testValue {
  if (NODE_ENV !== "production") {
    if (format === undefined) {
      throw new Error("invariant requires an error message argument");
    }
  }

  if (!testValue) {
    let error: Error & { framesToPop?: number };
    if (format === undefined) {
      error = new Error(
        "Minified exception occurred; use the non-minified dev environment " +
          "for the full error message and additional helpful warnings.",
      );
    } else {
      const args = [a, b, c, d, e, f];
      let argIndex = 0;
      error = new Error(format.replace(/%s/g, () => `${args[argIndex++]}`));
      error.name = "Invariant Violation";
    }

    error.framesToPop = 1; // we don't care about invariant's own frame
    throw error;
  }
}

export function ensureFound<T>(value: T | null | undefined): T {
  if (value == null) throw new Response("Not Found", { status: 404 });
  return value;
}
