import { useEffect, useLayoutEffect } from "react";
import { Outlet, redirect, useLoaderData, useRouteLoaderData, type LoaderFunctionArgs } from "react-router";
import { invariant } from "~/lib/utils";
import { TooltipProvider } from "./components/ui/tooltip";
import { getFeedUrl } from "./feed";
import { getAppToken } from "./user/token.server";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const token = await getAppToken(request, { user: true });
  if (!token) throw redirect("/home");
  const { user } = token;
  const feedUrl = await getFeedUrl(user.userId);
  return {
    user: {
      ...user,
      devMode: user.email === "kaspars@whimsical.com" || user.email === "kaspars@danc.is",
    },
    feedUrl,
  };
};

export function useUser() {
  const user = useRouteLoaderData<typeof loader>("with-user")?.user;
  invariant(user, "No user");
  return user;
}

export function useFeedUrl() {
  const feedUrl = useRouteLoaderData<typeof loader>("with-user")?.feedUrl;
  invariant(feedUrl, "No feedUrl");
  return feedUrl;
}

export type User = ReturnType<typeof useUser>;

export function userFromMatches(matches: { id: string; data: unknown }[]) {
  const data = matches.find((m) => m.id === "with-user")?.data as Awaited<ReturnType<typeof loader>>;
  invariant(data, "No data");
  return data.user;
}

export default function WithUser() {
  const { user } = useLoaderData<typeof loader>();
  const theme = user.prefs.theme ?? "system";
  useEffect(() => {
    document.documentElement.classList.toggle(
      "dark",
      theme === "dark" || (theme === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches),
    );
    // store theme in localStorage so that on subsequent visits it can be set immediately in root layout which avoids flashing
    localStorage.theme = theme;
  }, [theme]);
  return (
    <TooltipProvider>
      <Outlet />
    </TooltipProvider>
  );
}
