/**
 * Logged-IN home page
 */

import type { EpisodeStatus } from "@prisma/client";
import * as Lucide from "lucide-react";
import { Link, redirect, useLoaderData, type LoaderFunctionArgs } from "react-router";
import { cn } from "~/lib/utils";
import { db } from "./.server/db";
import { Content, ContentBody } from "./components/ui/content";
import { EpisodeContent } from "./episode/episode";
import { getLatestEpisode, getNextEpisode } from "./episode/get.server";
import { episodeShortName, nextEpisodePublishDay } from "./episode/props";
import { formatTimeOfDay } from "./user/time";
import { requireAppToken } from "./user/token.server";
import { useUser } from "./with-user";

// LOADER

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { user } = await requireAppToken(request, {
    user: { select: { userId: true, episodeLimit: true, onboarded: true } },
  });
  if (!user.onboarded) {
    throw redirect("/setup/add-source");
  }
  const { userId } = user;
  const nextEpisode = await getNextEpisode(userId);
  const latestEpisode = user.episodeLimit > 0 ? await getLatestEpisode(userId) : null;
  const priorEpisodes =
    user.episodeLimit > 0
      ? await db.episode.findMany({
          where: {
            userId,
            episodeKey: { not: latestEpisode?.episodeKey },
            OR: [{ status: { not: "queued" } }, { scheduledAt: { lte: new Date() } }],
          },
          orderBy: { untilTime: "desc" },
          take: user.episodeLimit - 1,
          select: { episodeKey: true, untilTime: true, status: true },
        })
      : [];
  const sources = await db.source.count({ where: { userId, enabled: true } });
  return { nextEpisode, latestEpisode, priorEpisodes, sources };
};

// NEXT EPISODE

function ScheduleLink({ children }: { children: React.ReactNode }) {
  return (
    <Link
      to="/settings/schedule"
      className="text-foreground underline decoration-foreground/20 hover:decoration-foreground"
    >
      {children}
    </Link>
  );
}

function NextEpisode() {
  const { nextEpisode, sources } = useLoaderData<typeof loader>();
  const user = useUser();
  const sourcesText = sources === 1 ? "one source" : `${sources} sources`;

  return (
    <div className="pt-4 flex items-center gap-1">
      <TimelineEntry icon={TimelineIcon.Next} className="text-foreground/60">
        {nextEpisode ? (
          nextEpisode.catchup ? (
            <span>
              Summary creation is paused until{" "}
              <ScheduleLink>{nextEpisodePublishDay(nextEpisode, user, true)}</ScheduleLink>
            </span>
          ) : (
            <span>
              Next summary will be created{" "}
              <ScheduleLink>
                {nextEpisodePublishDay(nextEpisode, user)} at {formatTimeOfDay(user)}
              </ScheduleLink>{" "}
              from{" "}
              <Link
                to="/settings/sources"
                className="text-foreground underline decoration-foreground/20 hover:decoration-foreground"
              >
                {sourcesText}
              </Link>
            </span>
          )
        ) : (
          <span>
            Summary creation is <ScheduleLink>paused</ScheduleLink>
          </span>
        )}
      </TimelineEntry>
    </div>
  );
}

// function GeneratePreview() {
//   return (
//     <Form method="post" className="pt-8 flex">
//       <button type="submit" className="text-blue-500 rounded-md group">
//         <TimelineEntry icon={TimelineIcon.Generate} className="pb-0  group-hover:bg-white">
//           Generate preview
//         </TimelineEntry>
//       </button>
//     </Form>
//   );
// }

// EPISODE NAME

function EpisodeName({
  episode,
  latest,
}: { episode: { episodeKey: string; untilTime: Date; status?: EpisodeStatus }; latest?: boolean }) {
  const user = useUser();
  const status = episode.status;
  const icon =
    status === "ok"
      ? latest
        ? TimelineIcon.Ready
        : TimelineIcon.Past
      : status === "error"
        ? TimelineIcon.Error
        : TimelineIcon.Processing;
  return (
    <h2 className="pt-8">
      <Link to={`/${episode.episodeKey}`} className="group inline-block rounded-full">
        <TimelineEntry
          icon={icon}
          className="pb-0 group-hover:bg-card group-hover:outline-border group-hover:shadow rounded-full font-semibold"
        >
          {episodeShortName(episode, user)}
        </TimelineEntry>
      </Link>
    </h2>
  );
}

// LATEST EPISODE

function LatestEpisode() {
  const { latestEpisode } = useLoaderData<typeof loader>();
  if (!latestEpisode) {
    //return <GeneratePreview />;
    return null;
  }
  return (
    <>
      <EpisodeName episode={latestEpisode} latest={true} />
      <Content className="mt-2 ml-7">
        <ContentBody className="px-10 py-8">
          <EpisodeContent episode={latestEpisode} />
        </ContentBody>
      </Content>
    </>
  );
}

// PRIOR EPISODES

function PriorEpisodes() {
  const { priorEpisodes } = useLoaderData<typeof loader>();
  return (
    <>
      {priorEpisodes.map((episode) => (
        <EpisodeName key={episode.episodeKey} episode={episode} />
      ))}
    </>
  );
}

// TIMELINE

enum TimelineIcon {
  Next = 0,
  Ready = 1,
  Processing = 2,
  Error = 3,
  Past = 4,
  Generate = 5,
}

function TimelineEntry({
  children,
  className,
  icon,
}: { children: React.ReactNode; className?: string; icon: TimelineIcon }) {
  return (
    <div className={cn("flex items-center z-10 relative bg-background rounded-md pr-2", className)}>
      {icon === TimelineIcon.Next && <Lucide.CircleDashed className="size-5 m-1 mr-2 text-gray-400" />}
      {icon === TimelineIcon.Ready && <Lucide.CircleCheck className="size-5 m-1 mr-2 text-green-500" />}
      {icon === TimelineIcon.Processing && <Lucide.CircleEllipsis className="size-5 m-1 mr-2 text-yellow-500" />}
      {icon === TimelineIcon.Error && <Lucide.CircleX className="size-5 m-1 mr-2 text-red-500" />}
      {icon === TimelineIcon.Past && <Lucide.CircleCheck className="size-5 m-1 mr-2 text-gray-400" />}
      {icon === TimelineIcon.Generate && <Lucide.CirclePlus className="size-5 m-1 mr-2 text-blue-500" />}
      <span className="flex-grow ">{children}</span>
    </div>
  );
}

function Timeline() {
  const { priorEpisodes, latestEpisode } = useLoaderData<typeof loader>();
  const hasLatestEpisode = latestEpisode != null;
  const hasPriorEpisodes = priorEpisodes.length > 0;
  return (
    <div className="content-w relative mb-8">
      <div
        className="border-l-2 border-foreground/10 dark:border-foreground/20 pl-4 absolute -top-[14px] left-[13px]"
        style={{ height: hasPriorEpisodes ? "calc(100%)" : hasLatestEpisode ? "100px" : "50px" }}
      />
      <NextEpisode />
      <LatestEpisode />
      <PriorEpisodes />
    </div>
  );
}

// PAGE

export default function Index() {
  return <Timeline />;
}
