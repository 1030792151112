import type { SourceType } from "@prisma/client";
import { multimethod } from "@whimsical-code/multimethod";
import React from "react";
import { StandaloneDialog, type StandaloneDialogRef } from "~/components/ui/dialog";

export const AuthDialogContent = multimethod<
  [{ sourceType: SourceType; closeDialog: () => void }],
  SourceType,
  React.ReactNode
>(({ sourceType }) => sourceType);

export const AuthDialog = React.forwardRef<StandaloneDialogRef, { sourceType: SourceType }>(({ sourceType }, ref) => {
  function closeDialog() {
    (ref as React.MutableRefObject<StandaloneDialogRef>).current?.close();
  }

  return (
    <StandaloneDialog ref={ref}>
      <AuthDialogContent sourceType={sourceType} closeDialog={closeDialog} />
    </StandaloneDialog>
  );
});
