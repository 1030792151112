import { type ActionFunction, redirect } from "react-router";
import { UtilLayout } from "./layout";

export const action: ActionFunction = async (_) => {
  // Provide default action to not log errors on random vulnerability scanners
  return redirect("/");
};

export default function NotFoundPage() {
  return (
    <UtilLayout>
      <p className="mb-8 text-center text-muted-foreground">Sorry, there's nothing here.</p>
    </UtilLayout>
  );
}
