import type { Prisma } from "@prisma/client";

export const MIN_TOPIC_CHARS = 32;
export const DEFAULT_POSTS = 10;
export const DEFAULT_MAX_TOPICS = 3;
export const DEFAULT_MAX_TOPIC_CHARS = 300;

export interface RedditSourceConfig extends Prisma.JsonObject {
  groupBySubreddit?: boolean;
  posts?: number;
  maxTopicsPerPost?: number;
  maxTopicChars?: number;
}
